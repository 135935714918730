import React from 'react';
import SEO from '../../../components/SEO';
import growerIntegrationImg from '../../../images/cai_services_dataintegration_grower.png';
import enterpriseIntergrationImg from '../../../images/cai_services_dataintegration_enterprise.png';
import SectionLayout from '../../../layouts/SectionLayout';

export default function DataIntegrationServices() {
  return (
    <>
      <SEO title="Services - Data Integration" />
      <SectionLayout>
        <p className="section-display-subheader">
          All YOUR DATA SOURCES. UNIFIED.
        </p>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              Many Ag operations utilize different sources of data —from paper
              maps, to Excel sheets or real-time machine sensor feeds. Our data
              integration IT services convert your analog data into digital
              archives, pull, combine and display sources such as Machine sensor
              data, Ag Assets, Ag products, and Historical data — saving you
              time and money.
            </p>
          </div>
        </div>

        <h4 className="section-display-subheader" style={{ color: '#d5e100' }}>
          We offer data integration services for two scales of business:
        </h4>
        <h4 className="section-display-subheader">
          <span style={{ color: '', fontWeight: 700 }}>01.</span> Individual
          Grower Integration
        </h4>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              We provide individual Ag operations with the necessary service
              staff to digitize paper records, machine and sensor data -
              supporting both automated and manual feeds. It’s everything you
              need displayed in one easy-to-use place.
            </p>
          </div>
        </div>

        <div className="row mobile-desktop-img-margin">
          <div className="col-sm-12 text-center">
            <img
              className="img-fluid"
              alt="gis mapping services overview img"
              src={growerIntegrationImg}
            />
          </div>
        </div>
        <h4 className="section-display-subheader">
          <span style={{ fontWeight: 'bold' }}>02.</span> Enterprise Company
          Integration
        </h4>
        <div className="row mobile-desktop-text-margin">
          <div className="col-sm-12">
            <p>
              In some cases, larger Ag operations may already have their own
              cloud-based databases such as ERP software that house existing
              data, including products, inventories, employees, and equipment,
              etc. Rather than re-creating this data, our development team can
              connect existing IT infrastructure with our system providing
              streamlined data connections between systems.
            </p>
          </div>
        </div>
        <div className="row mobile-desktop-img-margin">
          <div className="col-sm-12 text-center">
            <img
              className="img-fluid"
              alt="gis mapping services overview img"
              src={enterpriseIntergrationImg}
            />
          </div>
        </div>
      </SectionLayout>
    </>
  );
}
